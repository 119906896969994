// import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useCountdown } from './hooks/useCountdown';
import DateTimeDisplay from './hooks/DateTimeDisplay';
import { clear } from '@testing-library/user-event/dist/clear';
import { FaPlayCircle } from "react-icons/fa";
import useSound from 'use-sound';
import boopSfx from './sounds/mixkit-appliance-ready-beep-1076.wav';
function App() {


  const TimeInput = () => {
    const [value, setValue] = useState("0:00");
    const [timeLeft, setTimeLeft] = useState(null);
    const [isCountdown, setCountdown] = useState(false);
    const [play] = useSound(boopSfx);

    useEffect(() => {
      if (timeLeft === 0) {
        // console.log("TIME LEFT IS 0");
        setCountdown(false)
        setTimeLeft(null)
        play();
      }

      // exit early when we reach 0
      if (!timeLeft) return;

      // save intervalId to clear the interval when the
      // component re-renders
      setCountdown(true)
      const intervalId = setInterval(() => {
        // setValue();
        const time = toHHMMSS(timeLeft - 1);
        setValue(time);
        setTimeLeft(timeLeft - 1);
        // console.log(timeLeft);
      }, 1000);

      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId);
      // add timeLeft as a dependency to re-rerun the effect
      // when we update it
    }, [timeLeft]);

    const onChange = (event) => {
      setValue(event.target.value);
    };

    const onBlur = (event) => {
      const value = event.target.value;
      const seconds = Math.max(0, getSecondsFromHHMMSS(value));

      const time = toHHMMSS(seconds);
      setValue(time);
    };

    const getSecondsFromHHMMSS = (value) => {
      const [str1, str2, str3] = value.split(":");

      const val1 = Number(str1);
      const val2 = Number(str2);
      const val3 = Number(str3);

      if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
        return val1;
      }

      if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
        return val1 * 60 + val2;
      }

      if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
        return val1 * 60 * 60 + val2 * 60 + val3;
      }

      return 0;
    };

    const toHHMMSS = (secs) => {
      const secNum = parseInt(secs.toString(), 10);
      const hours = Math.floor(secNum / 3600);
      const minutes = Math.floor(secNum / 60) % 60;
      const seconds = secNum % 60;

      return [hours, minutes, seconds]
        .map((val) => (val < 10 ? `0${val}` : val))
        .filter((val, index) => val !== "00" || index > 0)
        .join(":")
        .replace(/^0/, "");
    };

    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        event.target.blur();

      }
    }

    const CountdownTimer = () => {
      // alert("Great Shot!");
      // console.log('test');
      // console.log(value);
      // console.log(getSecondsFromHHMMSS(value));
      var _timeCountdown = getSecondsFromHHMMSS(value);

      if (_timeCountdown > 0) {

        setTimeLeft(_timeCountdown);

      } else {
        // console.log('no countdown');

      }

      // if(){}
    }


    return (
      <div>
        <h6 style={{
          marginBottom: '0.5rem'
        }} className={(isCountdown ? ' display-none ' : '') + ' description-text'}>Input your time in <b>secound</b> or <b>hh:mm:ss</b></h6>
        <input className={(isCountdown ? 'input-time-countdown' : '') + ' input-time '} type="text" onChange={onChange} onBlur={onBlur} value={value} onKeyDown={handleKeyPress} disabled={isCountdown} />
       {/* <div style='clear:both'></div> */}
       <br/>
        <Button style={{ fontSize: 30,
        marginTop : '0.8rem',
        backgroundColor : 'rgb(72 72 72)',
        
        }} className={(isCountdown ? ' display-none ' : '') } onClick={CountdownTimer} variant="primary" size="lg">
          <FaPlayCircle style={{marginRight:'0.5rem'}} /> Start
        </Button></div>
    );
  };

  // const checkValueIsNumberOrNot = (inputValue) => {
  //   //Handler called on button click
  //   if (isNaN(+inputValue)) {
  //     //if input is not a number then here
  //     alert('It is not a Number');
  //   } else {
  //     //if input is number then here
  //     alert('It is a Number');
  //   }
  // };

  // const CountdownTimer = ({ targetDate }) => {
  //   const [days, hours, minutes, seconds] = useCountdown(targetDate);

  //   if (days + hours + minutes + seconds <= 0) {
  //     return <ExpiredNotice />;
  //   } else {
  //     return (
  //       <ShowCounter
  //         days={days}
  //         hours={hours}
  //         minutes={minutes}
  //         seconds={seconds}
  //       />
  //     );
  //   }
  // };

  const ExpiredNotice = () => {
    return (
      <div className="expired-notice">
        <span>Expired!!!</span>
        <p>Please select a future date and time.</p>
      </div>
    );
  };

  const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="show-counter">
        <a
          href="https://tapasadhikary.com"
          target="_blank"
          rel="noopener noreferrer"
          className="countdown-link"
        >
          <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3} />
          <p>:</p>
          <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
        </a>
      </div>
    );
  };



  return (
    <div className="App">
      <header className="App-header">
        <TimeInput />

        {/* <CountdownTimer /> */}
      </header>
    </div>
  );
}

export default App;
